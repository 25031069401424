<template>
	<form v-if="hasItems" @change="filtrate" class="form__row_margin form form__row">
		<label class="form__label form__col_3">
      <!--<span class="form__labelTitle">Type</span>-->
			<select class="form__input" v-model="type" name="type"
              :style="opacity(type)"
      >
				<option :value="0">Select type</option>
				<option v-for="type in types" :value="type.id">{{ type.name }}</option>
			</select>
		</label>
		<label class="form__label form__col_3">
      <!--<span class="form__labelTitle">Style</span>-->
			<select class="form__input" v-model="style" name="style"
              :disabled="disabledStyle"
              :style="opacity(style)"
      >
				<option :value="0">{{ stylePlaceholder }}</option>
				<option v-for="style in styles" :value="style.id">{{ style.name }}</option>
			</select>
		</label>
		<label class="form__label form__col_3">
      <!--<span class="form__labelTitle">Grade</span>-->
			<select class="form__input" v-model="grade" name="grade"
              :disabled="disabledGrade"
              :style="opacity(grade)"
      >
				<option :value="0">{{ gradePlaceholder }}</option>
				<option v-for="grade in grades" :value="grade.id">{{ grade.name }}</option>
			</select>
		</label>
	</form>
</template>

<script>
	import {filtratePackages} from "../../helpers/search";
	import {getFilterGrades} from "../../helpers/search";
	import {getFilterStyles} from "../../helpers/search";

	export default {
		name: "PackageAndSetsFilter",
		props: ['dataFromParent'],
		data() {
			return {
				type: 0,
				style: 0,
				grade: 0,
				types: {},
				styles: [],
				grades: []
			}
		},
		methods: {
			getStyles(type) {
				this.styles = (_.toInteger(type) !== 0 && _.toString(type).length > 0) ? getFilterStyles(type, this.$parent.dataItems) : []
			},
			getGrades(type, style) {
				this.grades = (
					(_.toInteger(style) !== 0 && _.toString(style).length > 0)
					&&
					(_.toInteger(type) !== 0 && _.toString(type).length > 0)
				) ? getFilterGrades(type, style, this.$parent.dataItems) : []
			},
			prepareTypes(data) {
				this.types = {};
				if (Object.keys(data).length) {
					_.forEach(data, (i) => {
						this.types[i.type] = {
							id: i.type,
							name: i.type_name,
						};
					});
				}
				this.types = _.toArray(this.types)
			},
			filtrate() {
				this.$parent.dataItems = filtratePackages(
					this.type,
					this.style,
					this.grade,
					this.dataFromParent
				);
			},
      opacity(elem){
        if(!elem){
          return 'color: #B5B5B5';
        }
      }
		},
		watch: {
			dataFromParent(n) {
				this.prepareTypes(n)
			},
			type(n) {
				this.style = 0;
				this.grade = 0;
				this.filtrate();
				this.getStyles(n);
			},
			style(n) {
				this.grade = 0;
				this.getGrades(this.type, n);
			}
		},
		computed: {
			hasItems() {
				return Object.keys(this.dataFromParent).length > 0;
			},
			stylePlaceholder() {
			    return _.toInteger(this.type) !== 0 ? 'Select style' : 'Select type first';
			},
			gradePlaceholder() {
                return _.toInteger(this.style) !== 0 ? 'Select grade' : 'Select style first';
			},
			disabledStyle() {
                return _.toInteger(this.type) === 0;
			},
			disabledGrade() {
                return _.toInteger(this.style) === 0;
			}
		},
		created() {
			if (this.dataFromParent) {
				this.prepareTypes(this.dataFromParent)
			}
		}
	}
</script>

<style scoped>

</style>
